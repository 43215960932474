var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"patients"},[(_vm.loading)?[_c('div',{staticClass:"patients-header"},[_c('Skeleton',{staticClass:"margin-y",attrs:{"width":"10%","height":"25px","borderRadius":"16px"}}),_c('Skeleton',{staticClass:"margin-y",attrs:{"width":"10%","height":"25px","borderRadius":"16px"}})],1),_c('div',{staticClass:"page-segment"},[_c('div',{staticClass:"page-segment-content"},[_c('Skeleton',{attrs:{"width":"30%","height":"21px","borderRadius":"16px"}}),_vm._l((9),function(item,indexSkeleton){return _c('Skeleton',{key:indexSkeleton + 'a',staticClass:"margin-top-sm",attrs:{"width":"100%","height":"126px","borderRadius":"16px"}})})],2)])]:_vm._e(),(_vm.totalOfCount > 0 && !_vm.loading)?_c('div',{staticClass:"segment"},[_c('div',{staticClass:"patients-header"},[_c('div',{staticClass:"heading-small"},[_vm._v(" "+_vm._s(_vm.getTitle)+" ")]),_c('div',[_c('div',{staticClass:"content-title cursor-pointer",on:{"click":_vm.openModal}},[_vm._v(" "+_vm._s(_vm.$translations.patients.order)+" "),_c('div',{staticClass:"icon-arrow-small-outline-down icon-display"})]),(_vm.toggleModal)?_c('MenuDialog',{attrs:{"options":_vm.filterOptions,"option-selected":_vm.filterSelected,"mobile-mode":true},on:{"close":_vm.closeModal,"action":_vm.setFilter}}):_vm._e()],1)])]):_vm._e(),(
      _vm.totalOfCount > 0 &&
      _vm.filterSelected === _vm.orderType.APPOINTMENT_CREATED &&
      !_vm.loading
    )?_c('div',{staticClass:"patients-wrapper-appointments"},[_vm._l((_vm.patientsFilters),function(item){return _vm._l((item.months),function(value,index){return _c('div',{key:index + item.label,staticClass:"page-segment"},[_c('div',{staticClass:"page-segment-content"},[_c('div',{staticClass:"patients-appointments"},[_c('div',{staticClass:"patients-appointments-month heading-small",domProps:{"textContent":_vm._s(`${value.label} ${item.label}`)}}),_vm._l((value.users),function(patient,indexUsers){return _c('div',{key:indexUsers + patient.next_appointment,staticClass:"patients-appointments-day"},[_c('div',{staticClass:"patients-appointments-day-label"},[_c('div',{staticClass:"content-title",domProps:{"textContent":_vm._s(
                    _vm.dateRow(patient.next_appointment, value.users, indexUsers)
                      .number
                  )}}),_c('div',{staticClass:"body-small text-gray-50",domProps:{"textContent":_vm._s(
                    _vm.dateRow(patient.next_appointment, value.users, indexUsers)
                      .name
                  )}})]),_c('PatientCardLarge',{attrs:{"patient":patient}})],1)})],2)])])})})],2):_vm._e(),(
      _vm.totalOfCount > 0 && _vm.filterSelected === _vm.orderType.ALPHABETIC && !_vm.loading
    )?[_c('div',{staticClass:"page-segment"},[_c('div',{staticClass:"patients-alphabetic page-segment-content"},_vm._l((_vm.patientsFilters),function(item,indexPatientsAlphabetic){return _c('div',{key:indexPatientsAlphabetic + item,staticClass:"patients-alphabetic-letter"},[_c('div',{staticClass:"content-title",domProps:{"textContent":_vm._s(item.label)}}),_c('div',{staticClass:"patients-alphabetic-content"},_vm._l((item.users),function(patient,indexUsersAlphabetic){return _c('PatientCardLarge',{key:indexUsersAlphabetic + patient,attrs:{"patient":patient}})}),1)])}),0)])]:_vm._e(),(_vm.infinityScroll)?_c('div',{staticClass:"segment"},[_c('div',{staticClass:"segment-content"},[_c('LoaderSimple',{staticClass:"margin-y"})],1)]):_vm._e(),(!_vm.loading && _vm.totalOfCount < 1)?[_c('div',{staticClass:"patients-data-zero"},[_c('img',{attrs:{"width":"120px","height":"120px","src":_vm.datazero.img,"alt":_vm.datazero.text}}),_c('div',{staticClass:"content-title",domProps:{"textContent":_vm._s(_vm.datazero.text)}})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }