<template>
  <div
    @click="goToDetail"
    class="section-container-white patient-card-large"
    v-if="patient"
  >
    <!-- patient information -->
    <div class="patient-card-large-body">
      <div class="patient-card-large-body-name">
        <div class="heading-tag" v-text="patient.name" />
        <Chip
          v-if="patient.tag"
          class="chip"
          :size="'small'"
          :color="`${patient.tag.color.toLowerCase()}`"
          :text="patient.tag[`name_${$store.getters['auth/user'].language}`]"
        />
        <Chip
          v-if="
            informedConsentOption &&
            informedConsentIconOptions[informedConsentOption.status] &&
            !chipLoading
          "
          size="circle"
          :color="chipOption.color"
          :show-icon="true"
          :icon="chipOption.icon"
        />
        <Skeleton
          v-if="chipLoading"
          width="22px"
          height="22px"
          border-radius="9999px"
        />
      </div>

      <div class="patient-card-large-information">
        <div class="body-small" v-text="getGender" />
        <div class="body-small" v-text="getDate" />
        <div
          class="body-small"
          v-text="
            `${patient.appointments_completed} ${$translations.patients['completed-appointment']}`
          "
        />
      </div>
    </div>

    <!-- patient detail button -->
    <div class="icon-arrow-big-outline-right icon-display" />
  </div>
</template>

<script>
import {
  isBefore,
  calculateAge,
  Chip,
  Skeleton,
  SpeContracts
} from '@seliaco/red-panda'
export default {
  name: 'PatientCardLarge',
  components: {
    Chip,
    Skeleton
  },
  props: {
    patient: Object
  },
  data () {
    return {
      informedConsentOption: {},
      informedConsentIconOptions: {
        CONTRACT_NOT_SENT: {
          color: 'orange',
          icon: 'icon-notes-on'
        },
        WAITING_FOR_SIGNATURE: {
          color: 'blue-dark',
          icon: 'icon-notes-on'
        },
        ALREADY_SIGNED: {
          color: 'green-dark-alt',
          icon: 'icon-notes-on'
        }
      },
      chipLoading: false
    }
  },
  mounted () {
    this.retrieveInformedConsent()
  },
  computed: {
    avatar () {
      return this.patient?.avatar || require('@/assets/images/avatar.png')
    },
    getDate () {
      const translation = this.$translations.patients
      const moment = this.patient.next_appointment
      const premsg = isBefore(moment.toDate(), new Date())
        ? translation['last-appointment']
        : translation['next-appointment']

      return `${premsg} ${moment.format('h:mm a')}`
    },
    getGender () {
      return `${
        this.$translations.gender[this.patient.gender]
      } • ${calculateAge(this.$moment(this.patient.birthday))} ${
        this.$translations.appointment.create.years
      }`
    },
    chipOption () {
      return this.informedConsentIconOptions[this.informedConsentOption?.status]
    }
  },
  methods: {
    goToDetail () {
      this.$router.push({
        name: 'PatientDetail',
        params: { id: this.patient.id },
        query: { back: this.$route.fullPath }
      })
    },
    retrieveInformedConsent () {
      this.chipLoading = true
      SpeContracts.retrieveInformedConsent(this.patient.id)
        .then((response) => {
          this.informedConsentOption = response
        })
        .catch((error) => {
          this.$toast({
            severity: 'error',
            text: error.message
          })
        })
        .finally(() => {
          this.chipLoading = false
        })
    }
  }
}
</script>
<style lang="sass" scoped>
.patient-card-large
  display: grid
  grid-template-columns: 1fr 20px
  align-items: center
  width: 100%
  &-body,
  &-information
    display: flex
    flex-direction: column
  &-body
    gap: 4px
    &-name
      display: flex
      gap: 8px
      align-items: center
  &-information
    gap: 2px
::v-deep
  .chip-circle
    padding: 6px
</style>
