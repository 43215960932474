export const monthsDefault = {
  12: {},
  11: {},
  10: {},
  9: {},
  8: {},
  7: {},
  6: {},
  5: {},
  4: {},
  3: {},
  2: {},
  1: {}
}

export const alphabeticDefault = {
  A: {},
  B: {},
  C: {},
  D: {},
  E: {},
  F: {},
  G: {},
  H: {},
  I: {},
  J: {},
  K: {},
  L: {},
  M: {},
  N: {},
  Ñ: {},
  O: {},
  P: {},
  Q: {},
  R: {},
  S: {},
  T: {},
  U: {},
  V: {},
  W: {},
  X: {},
  Y: {},
  Z: {}
}

export const clearObject = (obj, v) => {
  let objectClean = {}

  for (const prop in obj) {
    if (obj[prop] && obj[prop][v]) {
      objectClean = {
        [prop]: obj[prop],
        ...objectClean
      }
    }
  }
  return objectClean
}

export const patientOrderType = {
  APPOINTMENT_CREATED: 'APPOINTMENT_CREATED',
  ALPHABETIC: 'ALPHABETIC'
}
