<template>
  <div class="patients">
    <!-- loader -->
    <template v-if="loading">
      <div class="patients-header">
        <Skeleton
          class="margin-y"
          width="10%"
          height="25px"
          borderRadius="16px"
        />

        <Skeleton
          class="margin-y"
          width="10%"
          height="25px"
          borderRadius="16px"
        />
      </div>
      <div class="page-segment">
        <div class="page-segment-content">
          <Skeleton width="30%" height="21px" borderRadius="16px" />
          <Skeleton
            class="margin-top-sm"
            v-for="(item, indexSkeleton) in 9"
            :key="indexSkeleton + 'a'"
            width="100%"
            height="126px"
            borderRadius="16px"
          />
        </div>
      </div>
    </template>

    <!-- header -->
    <div class="segment" v-if="totalOfCount > 0 && !loading">
      <div class="patients-header">
        <div class="heading-small">
          {{ getTitle }}
        </div>
        <div>
          <div class="content-title cursor-pointer" @click="openModal">
            {{ $translations.patients.order }}
            <div class="icon-arrow-small-outline-down icon-display"></div>
          </div>

          <!-- modal -->
          <MenuDialog
            :options="filterOptions"
            :option-selected="filterSelected"
            :mobile-mode="true"
            @close="closeModal"
            @action="setFilter"
            v-if="toggleModal"
          />
        </div>
      </div>
    </div>

    <!-- order by appointment -->
    <div
      class="patients-wrapper-appointments"
      v-if="
        totalOfCount > 0 &&
        filterSelected === orderType.APPOINTMENT_CREATED &&
        !loading
      "
    >
      <template v-for="item in patientsFilters">
        <div
          class="page-segment"
          :key="index + item.label"
          v-for="(value, index) in item.months"
        >
          <div class="page-segment-content">
            <div class="patients-appointments">
              <div
                class="patients-appointments-month heading-small"
                v-text="`${value.label} ${item.label}`"
              />
              <div
                class="patients-appointments-day"
                v-for="(patient, indexUsers) in value.users"
                :key="indexUsers + patient.next_appointment"
              >
                <div class="patients-appointments-day-label">
                  <div
                    class="content-title"
                    v-text="
                      dateRow(patient.next_appointment, value.users, indexUsers)
                        .number
                    "
                  />
                  <div
                    class="body-small text-gray-50"
                    v-text="
                      dateRow(patient.next_appointment, value.users, indexUsers)
                        .name
                    "
                  />
                </div>
                <PatientCardLarge :patient="patient" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!-- order by alphabetic -->
    <template
      v-if="
        totalOfCount > 0 && filterSelected === orderType.ALPHABETIC && !loading
      "
    >
      <div class="page-segment">
        <div class="patients-alphabetic page-segment-content">
          <div
            class="patients-alphabetic-letter"
            v-for="(item, indexPatientsAlphabetic) in patientsFilters"
            :key="indexPatientsAlphabetic + item"
          >
            <div class="content-title" v-text="item.label" />
            <div class="patients-alphabetic-content">
              <PatientCardLarge
                :patient="patient"
                v-for="(patient, indexUsersAlphabetic) in item.users"
                :key="indexUsersAlphabetic + patient"
              />
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- infinity-scroll -->
    <div class="segment" v-if="infinityScroll">
      <div class="segment-content">
        <LoaderSimple class="margin-y" />
      </div>
    </div>

    <!-- datazero -->
    <template v-if="!loading && totalOfCount < 1">
      <div class="patients-data-zero">
        <img
          width="120px"
          height="120px"
          :src="datazero.img"
          :alt="datazero.text"
        />
        <div class="content-title" v-text="datazero.text" />
      </div>
    </template>
  </div>
</template>

<script>
import {
  LoaderSimple,
  SearchSpecialist,
  Skeleton,
  SpecialistsPatients,
  MenuDialog
} from '@seliaco/red-panda'
import PatientCardLarge from '../../components/cards/PatientCardLarge.vue'
import {
  alphabeticDefault,
  clearObject,
  monthsDefault,
  patientOrderType
} from './configuration/patient-helper'

export default {
  name: 'PatientsList',
  components: {
    PatientCardLarge,
    Skeleton,
    LoaderSimple,
    MenuDialog
  },
  data () {
    return {
      orderType: patientOrderType,
      loading: true,
      totalCount: null,
      infinityScroll: false,
      controller: null,
      preventMultipleCalls: false,
      totalOfPages: 0,
      totalOfCount: 0,
      page: 1,
      query: {},
      filterSelected: patientOrderType.ALPHABETIC,
      patients: [],
      patientsFilters: {},
      toggleModal: false,
      datazero: {
        img: SearchSpecialist,
        text: this.$translations.patients.datazero.list
      },
      filterOptions: [
        {
          name: this.$translations.patients.filters['last-appoinment'],
          icon: 'icon-timer-calendar-off',
          key: patientOrderType.APPOINTMENT_CREATED
        },
        {
          name: this.$translations.patients.filters.alphabetic,
          icon: 'icon-alphabetic',
          key: patientOrderType.ALPHABETIC
        }
      ]
    }
  },
  mounted () {
    this.getPatientsSpecialists()
    this.setListener()
  },
  destroyed () {
    this.removeListeners()
  },
  methods: {
    getPatientsSpecialists (query) {
      if (!this.infinityScroll) {
        this.loading = true
      }

      this.query = query || {
        ...this.query,
        page: this.page,
        per_page: 10,
        default_order_by: this.filterSelected
      }

      if (this.$route.query.tab !== 'all') {
        this.query = {
          ...this.query,
          status_relation: this.$route.query.tab.toUpperCase()
        }
      }

      SpecialistsPatients.list(this.query)
        .then((response) => {
          this.totalOfPages = response.headers.pages
          this.totalOfCount = response.headers.count

          if (this.page === 1) {
            this.patients = response.data
          } else {
            this.patients = [...this.patients, ...response.data]
          }

          switch (this.filterSelected) {
            case patientOrderType.APPOINTMENT_CREATED:
              this.mapperPatientsByDate(this.patients)
              break
            case patientOrderType.ALPHABETIC:
              this.mapperPatientsByAlphabetic(this.patients)
              break
          }
        })
        .catch()
        .finally(() => {
          if (!this.infinityScroll) {
            this.loading = false
          }
          this.infinityScroll = false
          this.preventMultipleCalls = false
        })
    },
    setFilter (filter) {
      this.patients = []
      this.filterSelected = filter
      this.patientsFilters = {}
      this.page = 1
      this.closeModal()
      this.getPatientsSpecialists()
    },
    mapperPatientsByDate (patients) {
      if (!patients) {
        this.patientsFilters = []
        return
      }

      const dates = { ...monthsDefault }

      patients.forEach((i) => {
        const user = {
          name: `${i.patient_first_name} ${i.patient_last_name}`,
          id: i.patient_id,
          pic: i.patient_profile_picture,
          tag: i.tag,
          gender: i.gender,
          birthday: i.birthday,
          appointments_completed: i.appointments_completed,
          next_appointment: this.$moment(i.next_appointment).tz(
            this.$store.getters.userTimezone
          )
        }

        const dateMoment = this.$moment(i.next_appointment).tz(
          this.$store.getters.userTimezone
        )
        const monthNumber = dateMoment.format('M')
        const yearNumber = dateMoment.format('YYYY')

        if (!dates[yearNumber]?.label) {
          dates[yearNumber] = {
            label: yearNumber,
            months: {
              [monthNumber]: { label: dateMoment.format('MMMM'), users: [user] }
            }
          }
        } else {
          if (!dates[yearNumber].months[monthNumber]?.label) {
            dates[yearNumber].months[monthNumber] = {
              label: dateMoment.format('MMMM'),
              users: [user]
            }
          } else {
            dates[yearNumber].months[monthNumber].users.push(user)
          }
        }
      })

      const array = clearObject(dates, 'label')

      this.patientsFilters = Object.values(array).sort((a, b) => {
        if (a.label < b.label) return -1
        if (a.label > b.label) return 1
        return 0
      })
    },
    mapperPatientsByAlphabetic (patients) {
      if (!patients) {
        return null
      }
      const alphabetic = { ...alphabeticDefault }

      patients.forEach((i) => {
        const user = {
          name: `${i.patient_first_name} ${i.patient_last_name}`,
          id: i.patient_id,
          pic: i.patient_profile_picture,
          gender: i.gender,
          birthday: i.birthday,
          tag: i.tag,
          appointments_completed: i.appointments_completed,
          next_appointment: this.$moment(i.next_appointment).tz(
            this.$store.getters.userTimezone
          )
        }

        const letter = user.name.charAt(0).toUpperCase()

        if (!alphabetic[letter]?.label) {
          alphabetic[letter] = {
            label: letter,
            users: [user]
          }
        } else {
          const exits = alphabetic[letter].users.find(
            (i) => i.name === user.name
          )
          if (!exits?.id) {
            alphabetic[letter].users.push(user)
          }
        }
      })
      const objectClean = clearObject(alphabetic, 'label')

      this.patientsFilters = {
        ...objectClean
      }
    },
    onScroll (event, isDocument = false) {
      if (this.page < this.totalOfPages && event) {
        const scrollTop = isDocument ? window.scrollY : event.target.scrollTop

        const scrollHeight = isDocument
          ? window.scrollMaxY ||
            Math.round(
              document.documentElement.scrollHeight -
                document.documentElement.clientHeight
            )
          : event.target.scrollHeight - event.target.clientHeight

        if (
          scrollTop + window.innerHeight / 2 > scrollHeight &&
          !this.preventMultipleCalls
        ) {
          this.infinityScroll = true
          this.page++
          this.preventMultipleCalls = true
          this.getPatientsSpecialists()
        } else {
          this.infinityScroll = false
        }
      }
    },
    async setListener () {
      this.controller = new AbortController()
      const container = document.getElementsByClassName(
        'main-container-content'
      )[0]

      window.addEventListener(
        'scroll',
        ($event) => this.onScroll($event, true),
        { signal: this.controller.signal }
      )
      container.addEventListener('scroll', this.onScroll, {
        signal: this.controller.signal
      })
    },
    removeListeners () {
      this.controller.abort()
    },
    openModal () {
      this.toggleModal = true
    },
    closeModal () {
      this.toggleModal = false
    }
  },
  computed: {
    getTitle () {
      return `${this.$translations.patients.tabs[this.$route.query.tab]} (${
        this.totalOfCount
      })`
    },
    dateRow () {
      return (moment, users, index) => {
        if (index > 0) {
          if (
            moment.format('DD') ===
            users[index - 1].next_appointment.format('DD')
          ) {
            return {
              number: '',
              name: ''
            }
          }
        }

        return {
          number: moment.format('DD'),
          name: moment.format('ddd')
        }
      }
    }
  },
  watch: {
    $route (route) {
      if (route.query.tab === 'all') {
        this.page = 1
        this.query.page = this.page
        if (this.query.status_relation) {
          delete this.query.status_relation
        }
      } else {
        this.page = 1
        this.query = {
          ...this.query,
          page: this.page,
          status_relation: route.query.tab.toUpperCase()
        }
      }
      this.getPatientsSpecialists(this.query)
    }
  }
}
</script>

<style lang="sass" scoped>
.patients-header,
.patients-header .content-title
  display: flex
  justify-content: space-between
  align-items: center
  gap: 11px
  position: relative

.patients
  height: 100vh

  &-data-zero
    display: flex
    flex-direction: column
    gap: 16px
    align-items: center
    margin-top: 150px

  &-alphabetic
    display: flex
    flex-direction: column-reverse
    &-letter
      display: flex
      gap: 18px
      margin-top: 8px
    &-content
      display: flex
      flex-direction: column
      gap: 8px
      width: 100%

  &-wrapper-appointments
    display: flex
    flex-direction: column-reverse
  &-appointments
    &-month
      margin-bottom: 21px
      &::first-letter
        text-transform: uppercase
    &-day
      display: flex
      gap: 18px
      margin-top: 8px
      &-label
        width: 24px
        .body-small
          &::first-letter
            text-transform: uppercase
          &::last-letter
            display: none
@media (min-width: 1040px)
  .patients
    height: auto
</style>
