var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.patient)?_c('div',{staticClass:"section-container-white patient-card-large",on:{"click":_vm.goToDetail}},[_c('div',{staticClass:"patient-card-large-body"},[_c('div',{staticClass:"patient-card-large-body-name"},[_c('div',{staticClass:"heading-tag",domProps:{"textContent":_vm._s(_vm.patient.name)}}),(_vm.patient.tag)?_c('Chip',{staticClass:"chip",attrs:{"size":'small',"color":`${_vm.patient.tag.color.toLowerCase()}`,"text":_vm.patient.tag[`name_${_vm.$store.getters['auth/user'].language}`]}}):_vm._e(),(
          _vm.informedConsentOption &&
          _vm.informedConsentIconOptions[_vm.informedConsentOption.status] &&
          !_vm.chipLoading
        )?_c('Chip',{attrs:{"size":"circle","color":_vm.chipOption.color,"show-icon":true,"icon":_vm.chipOption.icon}}):_vm._e(),(_vm.chipLoading)?_c('Skeleton',{attrs:{"width":"22px","height":"22px","border-radius":"9999px"}}):_vm._e()],1),_c('div',{staticClass:"patient-card-large-information"},[_c('div',{staticClass:"body-small",domProps:{"textContent":_vm._s(_vm.getGender)}}),_c('div',{staticClass:"body-small",domProps:{"textContent":_vm._s(_vm.getDate)}}),_c('div',{staticClass:"body-small",domProps:{"textContent":_vm._s(
          `${_vm.patient.appointments_completed} ${_vm.$translations.patients['completed-appointment']}`
        )}})])]),_c('div',{staticClass:"icon-arrow-big-outline-right icon-display"})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }